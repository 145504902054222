import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";

ResponsiveDialog.propTypes = {
  handleModal: PropTypes.func,
  text: PropTypes.string,
  processRemove: PropTypes.func,
  isTicket: PropTypes.bool,
};

export default function ResponsiveDialog({
  handleModal,
  text,
  processRemove,
  isTicket,
}) {
  const handleClose = () => {
    handleModal();
  };

  return (
    <div>
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Manage {isTicket ? "tickets" : "members"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button autoFocus onClick={processRemove}>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
