import { MEMBER_ROLES_DEF } from "../../statics/statics";

const members = [
  {
    name: "Dr. Elias Wolff",
    id: "75c8ecf4-55fe-4c9b-8400-808950c09853",
    role: MEMBER_ROLES_DEF.BACK_DEV,
    email: "email@gmail.com",
    company: "Google. Inc",
    joined: "10-10-2021",
  },
  {
    name: "Dr. Joe Stive",
    id: "75c8ecf4-55fe-4c9b-8400-808950c09851",
    role: MEMBER_ROLES_DEF.NA,
    email: "email2@gmail.com",
    company: "Apple. Inc",
    joined: "20-10-2021",
  },
  {
    name: "Dr. Joe Abdou",
    id: "75c8ecf4-55fe-4c9b-8400-808950c098581",
    role: MEMBER_ROLES_DEF.FRONT_DEV,
    email: "email3@gmail.com",
    company: "Apple. Inc",
    joined: "20-10-2021",
  },
];

export default members;
