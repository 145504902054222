export const ROLES = Object.freeze({
  PROJECT_MANAGER: "project_manager",
  DEVELOPER: "developer",
  SUBMITTER: "submitter",
});

export const MEMBER_ROLES = [
  "Not set",
  "Front end develope",
  "Back end developer",
  "Full stack developer",
  "Submitter",
];

export const MEMBER_ROLES_DEF = Object.freeze({
  FRONT_DEV: "Front end developer",
  BACK_DEV: "Back end developer",
  FULL_DEV: "Full stack developer",
  SUBMITTER: "Submitter",
  NA: "Not assigned",
});

export const TICKET_TYPE_MENU = [
  "Not set",
  "Bug/Error",
  "Feature",
  "Update",
  "Release",
  "Others",
];

export const TYPES_PROJECTS = [
  "Webiste",
  "Web application",
  "Design",
  "Abdroid/Ios Application",
  "Shop",
  "Others",
];

export const TICKET_STATUS_MENU = ["progress", "paused", "cancelled", "done"];

export const TICKET_PRIORITY_MENU = ["Not set", "Low", "Medium", "High"];
