import React, { useState, useLayoutEffect, useReducer, useRef } from "react";
import Page from "../../../components/Page";
import ButtonContainer from "src/components/ButtonContainer";
import {
  Container,
  Stack,
  Typography,
  Card,
  Grid,
  TextField,
  ListItem,
  ListItemText,
  Box,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DatePicker from "../../../components/DatePicker";
import Scrollbar from "src/components/Scrollbar";
import TypeMenu from "../components/TypeMenu";
import { useNavigate } from "react-router-dom";
import {
  TICKET_TYPE_MENU,
  TICKET_PRIORITY_MENU,
} from "../../../statics/statics";

import Project from "../../../statics/project/project";

const ACTIONS = Object.freeze({
  input: "CHANGE_INPUT",
  developer: "CHANGE_DEVELOPER",
  priority: "CHANGE_PRIORITY",
  type: "CHANGE_TYPE",
  date: "CHANGE_DATE",
});

const reducer = (ticketState, action) => {
  switch (action.type) {
    case ACTIONS.input:
      ticketState.title = action.value.title;
      ticketState.description = action.value.desc;
      break;
    case ACTIONS.developer:
      ticketState.developer = action.value;
      break;
    case ACTIONS.priority:
      ticketState.priority = action.value;
      break;
    case ACTIONS.type:
      ticketState.type = action.value;
      break;
    case ACTIONS.date:
      ticketState.expextedDelivery = action.value;
      break;
    default:
      return ticketState;
  }
  return ticketState;
};

const initialState = {
  title: "",
  description: "",
  developer: "",
  submitter: "Aoufi abderahmane",
  project: Project.basicInfo.title,
  type: "Not set",
  priority: "Not set",
  status: "progress",
  created: new Date().toString(),
  expextedDelivery: "",
};

function CreateTickets() {
  const navigate = useNavigate("/dashboard/app");
  const [loading, setLoading] = useState(true);
  const [devTeam, setDevTeam] = useState([]);
  const titleRef = useRef(null);
  const descRef = useRef(null);
  const [ticketState, dispatch] = useReducer(reducer, initialState);
  useLayoutEffect(() => {
    setTimeout(() => {
      const DevTeam = Project.team
        .filter((member) => member.role === "Developer")
        .map((member) => member.name);
      setDevTeam(DevTeam);
      setLoading(false);
    }, 2000);
  }, []);

  const validateInput = (title, desc) => {
    return title.length >= 3 && desc.length >= 5;
  };

  const validateSelect = () => {
    return (
      ticketState.priority !== "Not set" &&
      ticketState.type !== "Not set" &&
      ticketState.expextedDelivery
    );
  };

  const processCreatingTicket = () => {
    const title = titleRef.current.value;
    const desc = descRef.current.value;
    if (validateInput(title, desc) && validateSelect()) {
      dispatch({ type: ACTIONS.input, value: { title, desc } });
      setLoading(true);
      setTimeout(() => {
        navigate("/dashboard/app");
        // alert(JSON.stringify(ticketState, null, 2));
      }, 2000);
    }
  };

  return (
    <Page title="Create ticket">
      {loading ? (
        <Typography sx={{ textAlign: "center", mt: 4 }}>Loading ..</Typography>
      ) : (
        <Container>
          <Stack flexDirection="row" alignItems="center" mb={5}>
            <Typography variant="h4">Create new ticket</Typography>
          </Stack>
          <Card sx={{ p: 4 }}>
            <Scrollbar>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
              >
                <Typography variant="h5" gutterBottom>
                  Fill information below
                </Typography>
              </Stack>
              <Grid container>
                <Grid marginTop={2} item xs={12} md={6}>
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    marginBottom="8px"
                  >
                    Ticket title
                  </Typography>
                  <TextField
                    inputRef={titleRef}
                    type="text"
                    multiline
                    rows={2}
                    sx={{ width: "90%" }}
                  />
                </Grid>
                <Grid item marginTop={2} xs={12} md={6}>
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    marginBottom="8px"
                  >
                    Ticket description
                  </Typography>
                  <TextField
                    inputRef={descRef}
                    type="text"
                    multiline
                    rows={2}
                    sx={{ width: "90%" }}
                  />
                </Grid>
                <Grid item marginTop={2} xs={12} md={6}>
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    marginBottom="8px"
                  >
                    Assign developer
                  </Typography>
                  <TypeMenu
                    options={devTeam}
                    setSelctedItem={(item) => {
                      dispatch({
                        type: ACTIONS.developer,
                        value: item,
                      });
                    }}
                  />
                </Grid>
                <Grid item marginTop={2} xs={12} md={6}>
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    marginBottom="8px"
                  >
                    Ticket submitter
                  </Typography>
                  <ListItem
                    sx={{ width: "100%", padding: "8px 0" }}
                    id="submitter-name"
                    aria-haspopup="listbox"
                    aria-controls="lock-menu"
                  >
                    <ListItemText
                      sx={{ fontWeight: "bold" }}
                      primary={initialState.submitter + " (You)"}
                    />
                  </ListItem>
                </Grid>
                <Grid item marginTop={2} xs={12} md={6}>
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    marginBottom="8px"
                  >
                    Set type
                  </Typography>
                  <TypeMenu
                    options={TICKET_TYPE_MENU}
                    setSelctedItem={(item) => {
                      dispatch({
                        type: ACTIONS.type,
                        value: item,
                      });
                    }}
                  />
                </Grid>
                <Grid item marginTop={2} xs={12} md={6}>
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    marginBottom="8px"
                  >
                    Set priority
                  </Typography>
                  <TypeMenu
                    options={TICKET_PRIORITY_MENU}
                    setSelctedItem={(item) => {
                      dispatch({
                        type: ACTIONS.priority,
                        value: item,
                      });
                    }}
                  />
                </Grid>
                <Grid item marginTop={2} xs={12} md={6}>
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    marginBottom="8px"
                  >
                    Expected delivery
                  </Typography>
                  <Box padding="8px" />
                  <DatePicker
                    setDate={(date) =>
                      dispatch({ type: ACTIONS.date, value: date })
                    }
                  />
                </Grid>
                <Grid item marginTop={2} xs={12} md={6}>
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    marginBottom="8px"
                  >
                    Project
                  </Typography>
                  <ListItem
                    sx={{ width: "100%", padding: "8px 0" }}
                    id="submitter-name"
                    aria-haspopup="listbox"
                    aria-controls="lock-menu"
                  >
                    <ListItemText
                      sx={{ fontWeight: "bold", color: "#c5c5c5" }}
                      primary="Bug tracker"
                    />
                  </ListItem>
                </Grid>
              </Grid>

              <ButtonContainer
                link="/dashboard/app"
                textPosetive="Create"
                handleClick={processCreatingTicket}
                edit={true}
              />
            </Scrollbar>
          </Card>
        </Container>
      )}
    </Page>
  );
}

export default CreateTickets;
