import { sentenceCase } from "change-case";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
} from "@mui/material";
// components
import Page from "../../../components/Page";
import Label from "../../../components/Label";
import Scrollbar from "../../../components/Scrollbar";
import TableHead from "../components/TableHead";
import TicketMenu from "../components/TicketsMenu";
import Project from "../../../statics/project/project";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "title", label: "Title", alignRight: false },
  { id: "submiter", label: "Submiter", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
  { id: "deliveryDate", label: "Excpected delivery", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

export default function RoleUsers() {
  return (
    <Page title="My tickets">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            My tickets
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {Project.tickets.map((row, index) => {
                    const {
                      id,
                      title,
                      submitter,
                      type,
                      expectedDelivery,
                      status,
                    } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            padding={2}
                          >
                            <Typography variant="subtitle2" noWrap>
                              {title}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{submitter?.name}</TableCell>
                        <TableCell align="left">{type}</TableCell>
                        <TableCell align="left">{expectedDelivery}</TableCell>
                        <TableCell align="left">
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={
                                (status === "done" && "success") ||
                                (status === "process" && "secondary") ||
                                "error"
                              }
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell>
                        </TableCell>
                        <TableCell align="right">
                          <TicketMenu id={index} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
