import React, { useState, useEffect } from "react";
import { getProjectManagerInfo } from "../api/project-manager";
import { Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setProjects } from "../state/redux/reducer/routing";
import { useNavigate } from "react-router-dom";
import useCheckProject from "src/hooks/useCheckProject";

function Loading() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.member.id);
  const checkProject = () => {
    getProjectManagerInfo(userID).then(({ data }) => {
      if (data.projectManager?.projects.length === 0) {
        navigate("/dashboard/c/project");
        return dispatch(setProjects([]));
      }
      dispatch(setProjects(data.projectManager?.projects));
      return navigate("/dashboard/app");
    });
  };
  useEffect(checkProject, []);

  return (
    <Typography sx={{ textAlign: "center", mt: 4 }}>Loading ...</Typography>
  );
}

export default Loading;
