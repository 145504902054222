import { Navigate, useRoutes } from "react-router-dom";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";

export default function Router() {
  const routing = [
    { path: "login", element: <Login /> },
    { path: "register", element: <Register /> },
    { path: "*", element: <Navigate to="/login" replace /> },
  ];

  return useRoutes(routing);
}
