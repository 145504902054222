export const ROLES_TITLES = Object.freeze({
  project_manager: {
    title: "Project manager",
    quote: "Watch your project growing!",
  },
  developer: {
    title: "Developer",
    quote: "Get your ticket done!",
  },
  submitter: {
    title: "Submitter",
    quote: "Check your tickets and do more!",
  },
});
