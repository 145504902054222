import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

function ButtonContainer({ link, handleClick, edit, textPosetive }) {
  const navigate = useNavigate();
  const Container = styled("div")(({ theme }) => ({
    marginTop: "2rem",
    float: "right",
    padding: "1rem",
    display: "flex",
  }));
  return (
    <Container>
      <Button
        onClick={() => {
          navigate(link);
        }}
        size="large"
        sx={{ mr: 1, textDecoration: "none" }}
        variant="outlined"
      >
        Back
      </Button>
      {edit ? (
        <Button onClick={handleClick} size="large" variant="contained">
          {textPosetive}
        </Button>
      ) : null}
    </Container>
  );
}

export default ButtonContainer;
