import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { TICKET_STATUS_MENU } from "../../../statics/statics";

// material
import { Menu, MenuItem, IconButton, ListItemText } from "@mui/material";

// ----------------------------------------------------------------------

RolesList.propTypes = {
  handleStatus: PropTypes.func,
};

export default function RolesList({ handleStatus }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon="eva:arrow-ios-downward-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {TICKET_STATUS_MENU.map((role) => (
          <MenuItem
            onClick={() => {
              handleStatus(role);
            }}
            key={role}
            sx={{ color: "text.secondary" }}
          >
            <ListItemText
              primary={role}
              primaryTypographyProps={{ variant: "body2", py: 1 }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
