import { createSlice } from "@reduxjs/toolkit";
import { getMemberInfo } from "../../../../storage/auth";

const initialState = {
  memberRole: "",
  id: "",
  projects: null,
};

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    setInfo: (state, info) => {
      state.memberRole = info.payload.role;
      state.id = info.payload.id;
    },
    setProjects: (state, info) => {
      state.projects = info.payload;
    },
  },
});

export const { setInfo, setProjects } = rolesSlice.actions;
export default rolesSlice.reducer;
