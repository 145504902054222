const tickets = [
  {
    title: "Build interface",
    description: "Build all interfaces including pages",
    developer: "Aoufi Abderahmane",
    submitter: "Medrar khaled",
    project: "Track buggy",
    priority: "High",
    status: "progess",
    type: "feature",
    created: "10-10-2021",
    expectedDelivery: "25-10-2021",
  },
];

export default tickets;
