import React, { useLayoutEffect, useState } from "react";
import memberList from "../../../statics/project/members";
import { Typography, List, ListItem, Box, Button, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import Roles from "../components/Roles";
import { useParams, useNavigate } from "react-router-dom";

const useStyle = () => {
  const Header = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }));
  const InfoContainer = styled("div")(({ theme }) => ({
    width: "100%",
    marginTop: "2.5rem",
    padding: "0 8px ",
  }));
  const ButtonContainer = styled("div")(({ theme }) => ({
    marginTop: "2rem",
    float: "right",
    padding: "1rem",
    display: "flex",
  }));
  const Container = styled("div")(({ theme }) => ({
    backgroundColor: "white",
  }));
  return [Header, InfoContainer, ButtonContainer, Container];
};

function Profile() {
  const [Header, InfoContainer, ButtonContainer, Container] = useStyle();
  const userID = useParams().id;
  const navigate = useNavigate();
  const [member, setMember] = useState(null);
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    setTimeout(() => {
      setMember(memberList[userID]);
      setLoading(false);
    }, 2000);
  }, []);

  const handleRole = (selectedRole) => {
    const updatedMember = { ...member };
    updatedMember.role = selectedRole;
    setMember(updatedMember);
  };

  const updateMember = () => {
    // send the new member and back home
    setLoading(true);
    setTimeout(() => {
      navigate("/dashboard/manage-members");
    }, 2000);
  };

  return (
    <Container>
      {member && !loading ? (
        <Box>
          <Header>
            <Typography variant="h4" sx={{ color: "text.primary", mt: 2 }}>
              {member.name}
            </Typography>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              {member.email}
            </Typography>
          </Header>
          <InfoContainer>
            <List component="div" disablePadding>
              <ListItem>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", paddingBottom: "8px" }}
                >
                  Basic information
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1" sx={{ color: "text.primary" }}>
                  Full name : {member.name}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1" sx={{ color: "text.primary" }}>
                  Joined : {member.joined}
                </Typography>
              </ListItem>

              <ListItem>
                <Typography variant="body1" sx={{ color: "text.primary" }}>
                  Company : {member.company}
                </Typography>
              </ListItem>
              <ListItem>
                <Stack flexDirection="row" alignItems="center">
                  <Typography variant="body1" sx={{ color: "text.primary" }}>
                    Current role : {member.role}
                  </Typography>
                  <Roles handleRole={handleRole} />
                </Stack>
              </ListItem>
            </List>
          </InfoContainer>

          <ButtonContainer>
            <Button
              onClick={() => navigate("/dashboard/manage-members")}
              sx={{ mr: 1 }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button onClick={updateMember} variant="contained">
              Update
            </Button>
          </ButtonContainer>
        </Box>
      ) : (
        <Typography sx={{ textAlign: "center", mt: 4 }}>Loading ..</Typography>
      )}
    </Container>
  );
}

export default Profile;
