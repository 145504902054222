import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { MEMBER_ROLES } from "../../../statics/statics";

// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

// ----------------------------------------------------------------------

RolesList.propTypes = {
  handleRole: PropTypes.func,
};

export default function RolesList({ handleRole }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon="eva:arrow-ios-downward-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {MEMBER_ROLES.map((role) => (
          <MenuItem
            onClick={() => {
              handleRole(role);
            }}
            key={role}
            sx={{ color: "text.secondary" }}
          >
            <ListItemText
              primary={role}
              primaryTypographyProps={{ variant: "body2", py: 1 }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
