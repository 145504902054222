import React, { useState } from "react";
import {
  Container,
  Stack,
  Typography,
  Card,
  Grid,
  TextField,
} from "@mui/material";
import ButtonContainer from "src/components/ButtonContainer";
import Scrollbar from "src/components/Scrollbar";
import Page from "../../../components/Page";
import TypeMenu from "src/stacks/submitter/components/TypeMenu";
import { TYPES_PROJECTS } from "../../../statics/statics";
import DatePicker from "../../../components/DatePicker";
import { createProject } from "../../../api/project-manager";

function CreateProject() {
  const [type, setSelectedType] = useState(TYPES_PROJECTS[0]);
  const [deliveryDate, setDate] = useState(Date.now());
  const [projectBasicInfo, setProjectInfo] = useState({
    title: "project-name",
    description: "project-desc",
  });

  const handleBasicInfo = (e) => {
    const basicInfo = { ...projectBasicInfo };
    basicInfo[e.target.name] = e.target.value;
    setProjectInfo(basicInfo);
  };

  const processCreationProject = () => {
    const projectInfo = {
      basicInfo: {
        title: projectBasicInfo.title,
        description: projectBasicInfo.description,
        type,
        deliveryDate,
      },
      projectManager: "61a79c2063d0cdf09cb36cc4",
    };
    createProject(projectInfo)
      .then(({ data, status }) => {
        console.log(data, status);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };
  return (
    <Page title="Create project">
      <Container>
        <Container>
          <Stack flexDirection="row" alignItems="center" mb={5}>
            <Typography variant="h4">Create new project</Typography>
          </Stack>
          <Card sx={{ p: 4 }}>
            <Scrollbar>
              <Grid container>
                <Grid marginTop={2} item xs={12} md={6}>
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    marginBottom="8px"
                  >
                    Project title
                  </Typography>
                  <TextField
                    name="title"
                    onChange={handleBasicInfo}
                    type="text"
                    multiline
                    sx={{ width: "90%" }}
                  />
                </Grid>
                <Grid marginTop={2} item xs={12} md={6}>
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    marginBottom="8px"
                  >
                    Project Description
                  </Typography>
                  <TextField
                    name="description"
                    onChange={handleBasicInfo}
                    type="text"
                    multiline
                    sx={{ width: "90%" }}
                  />
                </Grid>
                <Grid item marginTop={2} xs={12} md={6}>
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    marginBottom="8px"
                  >
                    Project type
                  </Typography>
                  <TypeMenu
                    options={TYPES_PROJECTS}
                    setSelctedItem={(item) => {
                      setSelectedType(item);
                    }}
                  />
                </Grid>
                <Grid item marginTop={2} xs={12} md={6}>
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    marginBottom="8px"
                  >
                    Expected delivery date
                  </Typography>

                  <DatePicker
                    setDate={(chosenDate) => {
                      setDate(chosenDate);
                    }}
                  />
                </Grid>
              </Grid>
              <ButtonContainer
                link="/dashboard/app"
                textPosetive="Create project"
                handleClick={processCreationProject}
                edit={true}
              />
            </Scrollbar>
          </Card>
        </Container>
      </Container>
    </Page>
  );
}

export default CreateProject;
