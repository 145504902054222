// routes
import Router from "../routes/routes";
import { useCallback } from "react";
// theme
import ThemeConfig from "../theme";
import GlobalStyles from "../theme/globalStyles";
// components
import ScrollToTop from "../components/ScrollToTop";
import { BaseOptionChartStyle } from "../components/charts/BaseOptionChart";
import AdvancedRouting from "../routes/advancer-routing";
import { useSelector, useDispatch } from "react-redux";
import { getMemberInfo } from "../storage/auth";
import { setInfo } from "../state/redux/reducer/routing";
import Loading from "../components/Loading";

const App = () => {
  const role = getMemberInfo();
  const dispatch = useDispatch();
  if (role.id) {
    dispatch(setInfo(role));
  }

  const member = useSelector((state) => state?.member?.id);

  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      {member ? <AdvancedRouting /> : <Router />}
    </ThemeConfig>
  );
};

export default App;
