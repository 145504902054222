
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getProjectManagerInfo } from "../../../api/project-manager"
import { setProjects } from "../../../state/redux/reducer/routing"

const Loading = (WrappedComponent) => {
    const Loading = (props) => {
        const navigate = useNavigate();
        const dispatch = useDispatch()
        const userID = useSelector((state) => state.member.id);
        getProjectManagerInfo(userID).then(({ data }) => {
            if (data.projectManager?.projects.length === 0) {
                navigate("/dashboard/c/project");
                return dispatch(setProjects([]));
            }
            dispatch(setProjects(data.projectManager?.projects));
        });
        return <WrappedComponent {...props} />
    }
    return Loading;
}

export default Loading;