import { useState, createContext, useEffect } from "react";
import TableHead from "../components/TableMembersHead"
import Modal from "../../../components/Modal";
import DialogRemove from "../../shared/components/DialogRemove";
import Loading from "./Loading"
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
} from "@mui/material";
// components
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import UserMenu from "../components/UserMenu";
import Project from "../../../statics/project/project";
import { useDispatch, useSelector } from "react-redux";
import { getProject } from "../../../api/project-manager"

const TABLE_HEAD = [
  { id: "name", label: "User name", alignRight: false },
  { id: "eamil", label: "Email", alignRight: false },
  { id: "company", label: "Company", alignRight: false },
  { id: "role", label: "Role", alignRight: false },
  { id: "" },
];

function RoleUsers() {
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const projectID = useSelector(state => state.member?.projects[0]); // selected first project
  const projectManagerID = useSelector(state => state.member?.id); // selected first project
  const [team, setTeam] = useState([])

  useEffect(() => {
    getProject({ _id: projectID, projectManagerID: projectManagerID }).then(({ data }) => {
      setTeam(data.submitters)
    })
  }, [])

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const setSelectedMember = (id) => {
    setSelectedUser(id);
    handleModal();
  };

  const processRemove = () => {
    // pretend remove member
    setTimeout(() => {
      handleModal();
    }, 2000);
  };



  return (
    <Page title="Manage roles">
      <Modal open={openModal}>
        <DialogRemove
          processRemove={processRemove}
          handleModal={handleModal}
          text="Do you want to remove this member?"
        />
      </Modal>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Manage roles
          </Typography>
        </Stack>

        <Card sx={{minHeight : "200px"}}>
          <Scrollbar>
            {team.length === 0 ? <Typography mt={4} textAlign="center" >Looks like no team added ... </Typography> : <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {Project?.team.map((row, index) => {
                    const { id, name, role, company, email } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" paddingLeft={2}>
                            {name}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{company}</TableCell>
                        <TableCell align="left">{role}</TableCell>
                        <TableCell align="right">
                          <UserMenu
                            id={index}
                            handleModal={handleModal}
                            handleRemoveUser={setSelectedMember}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>}

          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}





const Usage = () => {
  const WrappedRoleUsers = Loading(RoleUsers);
  return <WrappedRoleUsers />;
}

export default Usage;