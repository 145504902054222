import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import { ROLES } from "../../statics/statics";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const projectManger = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "manage members",
    path: "/dashboard/manage-members",
    icon: getIcon(peopleFill),
  },
  {
    title: "project tickets",
    path: "/dashboard/tickets",
    icon: getIcon(peopleFill),
  },
];

const developer = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "My tickets",
    path: "/dashboard/tickets",
    icon: getIcon(peopleFill),
  },
  {
    title: "Todo list",
    path: "/dashboard/todo",
    icon: getIcon(peopleFill),
  },
];

const submitter = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "My tickets",
    path: "/dashboard/tickets",
    icon: getIcon(peopleFill),
  },
  {
    title: "create ticket",
    path: "/dashboard/create-ticket",
    icon: getIcon(peopleFill),
  },
];

const getSideBarConfiguration = (IDENTIFIER) => {
  if (!IDENTIFIER) return [];
  let sidebarConfig = [];
  switch (IDENTIFIER) {
    case ROLES.PROJECT_MANAGER:
      sidebarConfig = projectManger;
      break;

    case ROLES.SUBMITTER:
      sidebarConfig = submitter;
      break;

    case ROLES.DEVELOPER:
      sidebarConfig = developer;
      break;

    default:
      break;
  }
  return sidebarConfig;
};

export default getSideBarConfiguration;
