import * as React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import PropTypes from "prop-types";

MaterialUIPickers.propTypes = {
  setDate: PropTypes.func,
};

export default function MaterialUIPickers({ setDate }) {
  const [value, setValue] = React.useState(new Date().toString());

  const handleChange = (newValue) => {
    setValue(newValue);
    setDate(newValue.toString());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        inputFormat="MM/dd/yyyy"
        value={value}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}
