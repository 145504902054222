import { sentenceCase } from "change-case";
import { useState } from "react";
import TicketHead from "../components/TableTicketsHead";
import Modal from "../../../components/Modal";
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
} from "@mui/material";
// components
import Page from "../../../components/Page";
import Label from "../../../components/Label";
import Scrollbar from "../../../components/Scrollbar";
import DialogRemove from "../../shared/components/DialogRemove";
import TicketMenu from "../../shared/components/TicketMenu";
//
import Project from "../../../statics/project/project";
import { addTicket } from "../../../state/redux/reducer/project";
import { useDispatch, useSelector, useStore } from "react-redux";
import  Loading  from "./Loading"
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "title", label: "Title", alignRight: false },
  { id: "submiter", label: "Submiter", alignRight: false },
  { id: "developer", label: "Developer", alignRight: false },
  { id: "status", label: "status", alignRight: false },
  { id: "" },
];

function Tickets() {
  const dispatch = useDispatch();
  const projectName = useSelector((state) => state.project?.projectName);
  console.log(projectName);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState({});
  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleRemoveTicket = (id) => {
    handleModal();
    setSelectedTicket(id);
  };

  const processRemove = () => {
    dispatch(addTicket());
    setTimeout(() => {
      handleModal();
    }, 1000);
  };

  return (
    <Page title="Manage roles">
      <Modal open={openModal}>
        <DialogRemove
          isTicket={true}
          processRemove={processRemove}
          handleModal={handleModal}
          text="Do you want to remove this ticket?"
        />
      </Modal>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Project tickets
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TicketHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {Project.tickets.map((row, index) => {
                    const { id, title, submitter, developer, status } = row;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            padding={2}
                          >
                            <Typography variant="subtitle2" noWrap>
                              {title}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{submitter.name}</TableCell>
                        <TableCell align="left">{developer.name}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={
                              (status === "done" && "success") ||
                              (status === "process" && "secondary") ||
                              "error"
                            }
                          >
                            {sentenceCase(status)}
                          </Label>
                        </TableCell>
                        <TableCell align="right">
                          <TicketMenu
                            handleRemoveTicket={handleRemoveTicket}
                            id={index}
                            handleModal={handleModal}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}


const Usage = () => {
  const WrappedRickets = Loading(Tickets);
  return <WrappedRickets />;
}

export default Usage;