export const saveMemberInfo = ({ role, id }) => {
  localStorage.setItem("role", role);
  localStorage.setItem("id", id);
};

export const getMemberInfo = () => {
  return {
    role: localStorage.getItem("role"),
    id: localStorage.getItem("id"),
  };
};
