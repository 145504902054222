import React, { useState } from "react";
import Page from "../../../components/Page";
import ticketList from "../data/fake/tickets";
import Label from "../../../components/Label";
import { sentenceCase } from "change-case";
import TicketMenu from "../../shared/components/TicketMenu";
import Modal from "../../../components/Modal";
import {
  Stack,
  Container,
  Typography,
  Card,
  TableContainer,
  TableBody,
  Table,
  TableRow,
  TableCell,
} from "@mui/material";
import Scrollbar from "../../../components/Scrollbar";
import TableHead from "../components/TableHead";
import DialogRemove from "../../shared/components/DialogRemove";

const TABLE_HEAD = [
  { id: "title", label: "Title", alignRight: false },
  { id: "assigned_dev", label: "Assigned developer", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "deliveryDate", label: "Excpected delivery", alignRight: false },
  { id: "" },
];

function Tickets() {
  const [openModal, setOpenModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState({});
  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleRemoveTicket = (id) => {
    handleModal();
    setSelectedTicket(id);
  };

  const processRemove = () => {
    setTimeout(() => {
      handleModal();
    }, 1000);
  };
  return (
    <Page title="My tickets">
      <Modal open={openModal}>
        <DialogRemove
          handleModal={handleModal}
          processRemove={processRemove}
          text="Do you want to delete this ticket"
          ticket
        />
      </Modal>
      <Container>
        <Stack
          direction="row"
          mb={5}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" gutterBottom>
            My tickets
          </Typography>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer>
              <Table>
                <TableHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {ticketList.map((ticket, index) => {
                    const {
                      id,
                      title,
                      assignedDeveloper,
                      status,
                      expectedDelivery,
                    } = ticket;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            padding={2}
                          >
                            <Typography variant="subtitle2" noWrap>
                              {title}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{assignedDeveloper}</TableCell>

                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={
                              (status === "done" && "success") ||
                              (status === "process" && "secondary") ||
                              "error"
                            }
                          >
                            {sentenceCase(status)}
                          </Label>
                        </TableCell>
                        <TableCell align="left">{expectedDelivery}</TableCell>
                        <TableCell align="right">
                          <TicketMenu
                            handleRemoveTicket={handleRemoveTicket}
                            id={index}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}

export default Tickets;
