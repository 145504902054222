import axiosInstance from "../../statics/axios";

const createProject = (projectInfo) => {
  return axiosInstance.post("/c/project", projectInfo);
};

const getProject = (projectInfo) => {
  return axiosInstance.post("/g/project", projectInfo);
};

const getProjectManagerInfo = (projectInfoID) => {
  return axiosInstance.get("/g/project-manager/" + projectInfoID);
};

export { createProject, getProject, getProjectManagerInfo };
