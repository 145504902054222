import { Navigate, useRoutes } from "react-router-dom";

// layouts
import DashboardLayout from "../layouts/dashboard";

import DashboardApp from "../pages/Dashboard/DashboardApp";
import TicketsDeveloper from "../stacks/developer/pages/Tickets";
import TodoDeveloper from "../stacks/developer/pages/Todo";
import RolesUsers from "../stacks/project-manager/pages/RoleUsers";
import ProjectManagertTickets from "../stacks/project-manager/pages/Tickets";
import Profile from "../stacks/project-manager/pages/Profile";
import TicketInfo from "../stacks/shared/pages/TicketInfo";
import TicketsSubmitter from "../stacks/submitter/pages/Tickets";
import CreateTicket from "../stacks/submitter/pages/CreateTickets";
import CreateProject from "../stacks/project-manager/pages/CreateProject";
import PmDashBoard from "../stacks/project-manager/pages/Dashboard";
import Loading from "../components/Loading"

import { useSelector } from "react-redux";

const listRoles = {
  project_manager: [
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: "app", element: <PmDashBoard /> },
        { path: "manage-members", element: <RolesUsers /> },
        { path: "tickets", element: <ProjectManagertTickets /> },
        { path: "c/project", element: <CreateProject /> },
        { path: "profile/:id", element: <Profile /> },
        { path: "ticket/:id", element: <TicketInfo /> },
      ],
    },
    { path: "*", element: <Navigate to="/dashboard/app" replace /> },
  ],

  developer: [
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { path: "tickets", element: <TicketsDeveloper /> },
        { path: "app", element: <DashboardApp /> },
        { path: "todo", element: <TodoDeveloper /> },
        { path: "ticket/:id", element: <TicketInfo /> },
      ],
    },
    { path: "*", element: <Navigate to="/dashboard/app" replace /> },
  ],

  submitter: [
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { path: "tickets", element: <TicketsSubmitter /> },
        { path: "app", element: <DashboardApp /> },
        { path: "create-ticket", element: <CreateTicket /> },
        { path: "ticket/:id", element: <TicketInfo /> },
      ],
    },
    { path: "*", element: <Navigate to="/dashboard/app" replace /> },
  ],
};

export default function Router() {
  const currentRole = useSelector((state) => state.member?.memberRole);
  return useRoutes(listRoles[currentRole]);
}
