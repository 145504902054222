import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectName: "",
  projectID: "",
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProjectInfo: (state , info) => {
      state.projectName = info.payload.projectName;
      state.projectID = info.payload.projectID;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addTicket } = projectSlice.actions;

export default projectSlice.reducer;
