import React, { useState, useEffect } from "react";
import { Typography, Box, Grid, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import tickets from "../../../statics/project/tickets";
import StatusMenu from "../components/StatusMenu";
import ButtonContainer from "src/components/ButtonContainer";
import Loading from "src/components/Loading";
import { useParams, Navigate, useLocation } from "react-router-dom";

const useStyle = () => {
  const Container = styled("div")(({ theme }) => ({
    backgroundColor: "white",
  }));
  const Header = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }));
  return [Container, Header, ButtonContainer];
};

function TicketInfo() {
  let { search } = useLocation();
  const ticketID = useParams().id;

  const [Container, Header, ButtonContainer] = useStyle();
  const [ticket, setTicket] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [currentStatus, setCurrentStatus] = useState();
  useEffect(() => {
    setTimeout(() => {
      setTicket(tickets[ticketID]);
      if (search.split("=")[1]) {
        setIsEdit(true);
      }
    }, 1000);
  }, []);

  const handleStatus = (status) => {
    setCurrentStatus(status);
  };

  if (!ticketID) {
    return <Navigate to="/" />;
  }

  const processUpdate = () => {
    alert(currentStatus);
  };

  return (
    <Container>
      {ticket ? (
        <>
          <Header>
            <Typography variant="h4" sx={{ color: "text.primary", mt: 2 }}>
              {isEdit ? "Edit ticket " : "Ticket info"}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              track buggy
            </Typography>
          </Header>

          <Box sx={{ p: 2, mt: 4 }}>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.primary",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Ticket title
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {ticket?.title}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  sx={{ color: "text.primary", fontWeight: "bold" }}
                >
                  Ticket description
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {ticket.description}
                </Typography>
              </Grid>
              <Grid mt={3} item xs={6}>
                <Typography
                  variant="body1"
                  sx={{ color: "text.primary", fontWeight: "bold" }}
                >
                  Assigned developer
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {ticket.developer}
                </Typography>
              </Grid>

              <Grid mt={3} item xs={6}>
                <Typography
                  variant="body1"
                  sx={{ color: "text.primary", fontWeight: "bold" }}
                >
                  Submitter
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {ticket.submitter}
                </Typography>
              </Grid>

              <Grid mt={3} item xs={6}>
                <Typography
                  variant="body1"
                  sx={{ color: "text.primary", fontWeight: "bold" }}
                >
                  Project
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {ticket.project}
                </Typography>
              </Grid>

              <Grid mt={3} item xs={6}>
                <Typography
                  variant="body1"
                  sx={{ color: "text.primary", fontWeight: "bold" }}
                >
                  Type
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {ticket.type}
                </Typography>
              </Grid>

              <Grid mt={3} item xs={6}>
                <Typography
                  variant="body1"
                  sx={{ color: "text.primary", fontWeight: "bold" }}
                >
                  Status
                </Typography>
                <Stack flexDirection="row" alignItems="center">
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    {isEdit && currentStatus ? currentStatus : ticket.status}
                  </Typography>
                  {isEdit ? <StatusMenu handleStatus={handleStatus} /> : null}
                </Stack>
              </Grid>

              <Grid mt={3} item xs={6}>
                <Typography
                  variant="body1"
                  sx={{ color: "text.primary", fontWeight: "bold" }}
                >
                  Priority
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {ticket.priority}
                </Typography>
              </Grid>

              <Grid mt={3} item xs={6}>
                <Typography
                  variant="body1"
                  sx={{ color: "text.primary", fontWeight: "bold" }}
                >
                  Created
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {ticket.created}
                </Typography>
              </Grid>

              <Grid mt={3} item xs={6}>
                <Typography
                  variant="body1"
                  sx={{ color: "text.primary", fontWeight: "bold" }}
                >
                  Expected delivery
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {ticket.expectedDelivery}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <ButtonContainer
            handleClick={processUpdate}
            link="/dashboard/tickets"
            edit={isEdit}
            textPosetive="Update"
          />
        </>
      ) : (
        <Loading />
      )}
    </Container>
  );
}

export default TicketInfo;
