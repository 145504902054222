import { format } from "date-fns";
import faker from "faker";
import { sample } from "lodash";

const tickets = [...Array(2)].map((_, index) => ({
  id: faker.datatype.uuid(),
  title: faker.name.title(),
  assignedDeveloper: faker.name.findName(),
  status: sample(["process", "done", "deleted"]),
  expectedDelivery: getDate().toString(),
}));

function getDate() {
  return format(faker.date.future(), "MM/dd/yyyy");
}

export default tickets;
