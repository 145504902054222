import PropTypes from "prop-types";
import { Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import ButtonContainer from "src/components/ButtonContainer";
import Loading from "src/components/Loading";
// material
import {
  Box,
  Card,
  Checkbox,
  CardHeader,
  Typography,
  FormControlLabel,
  Container,
  Stack,
} from "@mui/material";
import Page from "../../../components/Page";
import todoList from "../../../statics/project/todo";

TaskItem.propTypes = {
  task: PropTypes.string,
  checked: PropTypes.bool,
  formik: PropTypes.object,
  clickedChecked: PropTypes.bool,
  id: PropTypes.string,
};

function TaskItem({ task, checked, formik, id, clickedChecked, ...other }) {
  const { getFieldProps } = formik;

  return (
    <Stack direction="row" justifyContent="space-between" sx={{ py: 0.75 }}>
      <FormControlLabel
        control={
          <Checkbox
            onClick={() => clickedChecked(id, !checked)}
            {...getFieldProps("checked")}
            value={task}
            checked={checked}
            {...other}
          />
        }
        label={
          <Typography
            variant="body2"
            sx={{
              ...(checked && {
                color: "text.disabled",
                textDecoration: "line-through",
              }),
            }}
          >
            {task}
          </Typography>
        }
      />
    </Stack>
  );
}

export default function AppTasks() {
  const [TASKS, setTASKS] = useState(todoList);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      checked: [TASKS[0].checked],
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const { values, handleSubmit } = formik;

  const clickedChecked = (id, isChecked) => {
    let updatedList = [...TASKS];
    updatedList.map((task) =>
      task.id === id ? (task.checked = isChecked) : task
    );
  };

  const handleUpdate = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <Page title="Todo">
      <Container>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography variant="h4" gutterBottom>
                To do
              </Typography>
            </Stack>
            <Card>
              <CardHeader title="You're doing great!" color="text.secondary" />
              <Box sx={{ px: 3, py: 1 }}>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    {TASKS.map((task) => (
                      <TaskItem
                        clickedChecked={clickedChecked}
                        key={task.id}
                        id={task.id}
                        task={task.content}
                        formik={formik}
                        checked={task.checked}
                      />
                    ))}
                  </Form>
                </FormikProvider>
              </Box>
              <ButtonContainer
                textPosetive="Save"
                edit={true}
                link="/dashboard/app"
                handleClick={handleUpdate}
              />
            </Card>
          </div>
        )}
      </Container>
    </Page>
  );
}
