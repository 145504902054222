const Project = {
  basicInfo: {
    id: "b0c80386-5662-486b-8849-b7917c7d5cfa",
    title: "Rustic PNG",
    desc: "Nam ipsam enim consequuntur id quos sit quae saepe. Explicabo aut ab quod nisi. Blanditiis nobis odio velit nihil ex eius odit.",
    type: "Web site",
    expectedDelivery: "10/11/2021",
  },
  projectManager: "Mamie Nikolaus I",
  team: [
    {
      name: "Dr. Elias Wolff",
      id: "75c8ecf4-55fe-4c9b-8400-808950c09853",
      role: "Developer",
      email: "email@gmail.com",
      company: "Google. Inc",
    },
    {
      name: "Kelli Willms I",
      id: "6af8cb6a-1a74-4fcb-9d91-0f039611067f",
      role: "Developer",
      email: "email@gmail.com",
      company: "Apple. Inc",
    },
    {
      name: "Kenny Emard",
      id: "d4e4a4ae-efbc-4277-a74c-7ed2df062a63",
      role: "Developer",
      email: "email@gmail.com",
      company: "Google. Inc",
    },

    {
      name: "Rick Douglas",
      id: "111b5088-f6b0-4cd6-9b4f-dd49b50a12a6",
      role: "Submitter",
      email: "email@gmail.com",
      company: "Netflix. Inc",
    },
    {
      name: "Cecilia Glover",
      id: "424c8a78-6142-4c7a-9428-6138f3f0db46",
      role: "Submitter",
      email: "email@gmail.com",
      company: "NetScape. Inc",
    },
  ],

  tickets: [
    {
      title: "Jersey",
      desc: "Eius totam minima laborum et in. Cumque quia fuga veritatis corporis sunt corrupti sequi incidunt.",
      developer: {
        name: "Mrs. Deborah Kling",
        id: "944791a9-a8a7-4d41-aabc-bc38a71178e8",
      },
      submitter: {
        name: "Allison Ryan",
        id: "080a12f2-fb6f-4dc6-9393-57c39142c5d3",
      },
      status: "process",
      type: "Bug/error",
      expectedDelivery: "05/18/2022",
      project: "Chief Jordanian",
      id: "123-123-11",
    },
    {
      title: "Forward",
      desc: "Nesciunt iste eligendi quia eum aut quisquam ut ea. Ad iure est quia id eveniet debitis dolor ut. Qui magni harum sed quia voluptates error nam.",
      developer: {
        name: "Jeremy Hartmann IV",
        id: "ddfc65a6-a7bd-4335-8ae2-106d806450c5",
      },
      submitter: {
        name: "Darnell D'Amore",
        id: "58028515-a0d5-45ba-8344-7b954a11fcb7",
      },
      status: "done",
      type: "Bug/error",
      expectedDelivery: "12/12/2021",
      project: "Towels Granite",
      id: "123-123-12",
    },
    {
      title: "Escudo",
      desc: "Et dolorem molestias fuga dolor quibusdam accusantium et culpa. Enim asperiores facilis cumque asperiores culpa et deserunt deleniti tempora. Est ut voluptatibus ut voluptatum et. Minus quis nulla ut in.",
      developer: {
        name: "Debra Bartell",
        id: "1f1f24c7-6e77-4cb0-8cd7-2656f53337ec",
      },
      submitter: {
        name: "Ron Schmeler",
        id: "e2e8cb3f-af2e-4934-b160-8dec8eec9a39",
      },
      status: "done",
      type: "Feature",
      expectedDelivery: "11/20/2021",
      project: "haptic SMS",
      id: "123-123-13",
    },
    {
      title: "Investment",
      desc: "Numquam sint maxime aut est fuga officiis. Deserunt necessitatibus et cumque qui libero. Voluptate qui dolores laborum. Perferendis omnis assumenda et ratione est autem.",
      developer: {
        name: "Trevor Crooks",
        id: "47415b26-a3fc-4663-ba14-79a8a4dd0208",
      },
      submitter: {
        name: "Tamara Boyer",
        id: "626cee78-6ea7-4b4a-8598-71aafc957586",
      },
      status: "done",
      type: "Update",
      expectedDelivery: "03/25/2022",
      project: "purple Island",
      id: "123-123-14",
    },
    {
      title: "robust",
      desc: "Sunt praesentium aliquam qui magnam eveniet. Rem fugiat laboriosam voluptatem assumenda illo. Labore sint consequatur earum est dolor expedita officia odio. Rerum rerum a sunt. Quibusdam fugit quo provident iste molestiae culpa optio labore expedita.",
      developer: {
        name: "Kirk Predovic I",
        id: "658d053a-a128-4921-940f-fe037e3d8ac9",
      },
      submitter: {
        name: "Marsha Koch",
        id: "a4f83652-d7aa-44ff-aeea-dd625d3206d4",
      },
      status: "deleted",
      type: "Bug/error",
      expectedDelivery: "10/01/2022",
      project: "Refined Functionality",
      id: "123-123-15",
    },
  ],
};

export default Project;
